import { Toaster } from "../../../components";
import {Axios} from "../../../lib";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import ADMIN_ACTIONS from "../actions";

const list = (payload, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_FAQ_LIST, status: true });
        const { data } = await Axios.get(API_ENDPOINTS.FAQ_CRUD, payload, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_FAQ_LIST, faqs: data.faqs, count: data.count });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_FAQ_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_FAQ_LIST, status: false });
    }
};

const update = (payload, query, loader, navigate) => async (dispatch) => {
    try {
        await Axios.patch(API_ENDPOINTS.FAQ_CRUD, payload, loader);

        if (payload.status) {
            Toaster(TOAST_TYPE.SUCCESS, payload.status === "ACTIVE" ? MESSAGES.FAQ.ACTIVE_SUCCESS : MESSAGES.FAQ.INACTIVE_SUCCESS);
            dispatch(list(query, loader));
        }
        else {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.FAQ.UPDATE_SUCCESS);
            navigate(APP_ROUTES.FAQS);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.FAQ.UPDATE_ERROR);
    }
};

const create = (payload, query, loader, navigate) => async (dispatch) => {
    try {
        await Axios.post(API_ENDPOINTS.FAQ_CRUD, payload, loader);
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.FAQ.CREATE_SUCCESS);
        navigate(APP_ROUTES.FAQS);

        dispatch(list(query, loader));
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.FAQ.CREATE_ERROR);
    }
};


const remove = (payload, query, loader) => async (dispatch) => {
    try {
        await Axios.delete(API_ENDPOINTS.FAQ_CRUD, { params: payload }, loader);
        Toaster(TOAST_TYPE.SUCCESS, MESSAGES.FAQ.REMOVE_SUCCESS);

        dispatch(list(query, loader));
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.FAQ.REMOVE_ERROR);
    }
};

const specific = (id, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SPECIFIC_FAQ, status: true });
        const { data } = await Axios.get(`/faq/${id}`, {}, loader);

        dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_FAQ, faqDetails: data });
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SPECIFIC_FAQ, status: false });
    }
    catch (err) {
        dispatch({ type: ADMIN_ACTIONS.REQUEST_SPECIFIC_FAQ, status: false });
    }
};


export {
    list,
    update,
    remove,
    specific,
    create,
};