export const API_URL = {
    PROPERTY_DETAIL: "property/details",
    VENDOR_PROPERTY_DETAIL: "property/details/vendor",
    EDIT_PROPERTY: "property",
    EDIT_CAR: "vendor/update/car",
    TOUR_API: "tour",
    CURRENCY_URL: "https://ipapi.co/currency",
    CALENDAR_URL: "property/calendar/pricing",
    PROPERTY_LIST: "property"
};

export const API_ENDPOINTS = {
    GET_USER_DETAILS: "/common/checkUserType",
    PROPERTY_CRUD: "/property",
    LIST_CARS: "/car",
    LIST_CATEGORIES: "/category",
    SPECIFIC_PROPERTY: "/property/details/vendor",
    TOUR_CRUD: "/tour",
    LIST_NOTIFICATIONS: "/notification/listing",
    LIST_AREAS_BUILDINGS: "/location/area/building",
    CREATE_UPDATE_AREA: "/location/area",
    CREATE_UPDATE_BUILDING: "/location/building",
    APPROVE_CAR: "/car/approve",
    CREATE_UPDATE_CATEGORY: "/category",
    CREATE_UPDATE_SUB_CATEGORY: "/category/subCategory",
    CREATE_UPDATE_LIST_CITIES: "/location/city",
    SPECIFIC_CITY_AREA_BUILDINGS: "/location/details",
    ADMIN_DASHBOARD: "/admin/dashboard",
    FAQ_CRUD: "/faq",
    ADMIN_INVOICE_LIST: "/payout/admin/invoiceList",
    VENDOR_INVOICE_LIST: "/payout/invoiceList",
    ADMIN_SPECIFIC_INVOICE: "/payout/admin/bookingsInInvoice",
    VENDOR_SPECIFIC_INVOICE: "/payout/bookingsInInvoice",
    MARK_PAYOUT: "/payout/admin/markPayoutSuccess",
    PROMOTION_CRUD: "/promotion",
    SPECIFIC_PROMOTION: "/promotion/promotionId",
    APPROVE_PROPERTY: "/property/approve",
    LIST_PROPERTY_BEDS: "/property/beds/category",
    LIST_PROPERTY_AMENITIES: "/amenity",
    LIST_SUB_ADMIN: "/admin/get/subadmins",
    UPDATE_SUB_ADMIN: "admin/update/subadmin",
    CREATE_SUB_ADMIN: "admin/create/subadmin",
    APPROVE_TOUR: "/tour/approve",
    LIST_USERS: "/admin/get/users",
    UPDATE_USERS: "/admin/update/user",
    SPECIFIC_USER: "/admin/get/user",
    LIST_VENDORS: "/vendor/list",
    SPECIFIC_VENDOR: "/vendor",
    UPDATE_VENDOR: "/vendor/admin",
    VOUCHER_CRUD: "/voucher",
    SPECIFIC_VOUCHER: "/voucher/voucherId",
    CLAIMED_VOUCHER_USERS: "/voucher/claimedUsers",
    CAR_CRUD: "/car",
    SPECIFIC_CAR: "car/details",
    BOOKING_PAYOUTS: "/payout/readyToPayoutBookings",
    SAVE_PAYOUT_CARD: '/payout/bankInfo',
    LIST_PAYOUT_CARD: '/payout/bankInfo/list',
    UPDATE_PAYOUT_CARD: '/payout/bankInfo',
    UPDATE_PAYOUT_CARD_STATUS: '/payout/bankInfo/manage',
    SPECIFIC_PAYOUT_CARD: '/payout/bankInfo',
    DELETE_PAYOUT_CARD: '/payout/bankInfo',
    GENERATE_INVOICE: "/payout/generateInvoice",
    PROPERTY_PROMOTION_CRUD: "/promotion/property",
    LIST_UPDATE_PROPERTY_PRICES: "/property/calendar/pricing",
    LIST_BOOKING_REQUESTS: "/vendor/booking/requests",
    SPECIFIC_BOOKING_REQUEST: "/booking/reservationDetails",
    LIST_PROPERTY_RESERVATIONS: "/property/reservations",
    LIST_CAR_RESERVATIONS: "/car/reservations",
    ACCEPT_BOOKINGS: "/booking/acceptPayment",
    TOUR_SUB_CATEGORY_CRUD: "tour/subCategory",
    SPECIFIC_TOUR: "tour/details/vendor",
    LIST_TOUR_PRICES: "/tour/calendarData",
    UPDATE_TOUR_CALENDAR: "/tour/calendar",
    LIST_CMS: "/cms",
    UPDATE_CMS: "/admin/update/cms",
    AIRBNB_PROPERTY: "/property/airbnb/listing",
};