import React, { useEffect, useState } from "react";
import { Axios } from "../../lib";
import CanvasJSReact from "../../assets/chart/canvasjs.react";
// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Linecharttwo({ loader }) {
    const months = {
        0: "Jan",
        1: "Feb",
        2: "Mar",
        3: "Apr",
        4: "May",
        5:"Jun",
        6: "Jul",
        7: "Aug",
        8: "Sep",
        9: "Oct",
        10: "Nov",
        11: "Dec",
    }
    const [adminDash, setadminDash] = useState([]);
    const adminDashboardgraph = async () => {
        try {
            const { status, data } = await Axios.get("/revenue/monthly/graph?type=CAR&graphYear=2023", {}, loader);
            if (status) setadminDash(data);
        } catch (error) {
            console.error("ERROR-->", error);
        }
    };
  
    useEffect(() => {
        adminDashboardgraph();
    }, []);

    const options = {
        animationEnabled: true,
        theme: "light2",
        // title: {
        //     text: "Basic Column Chart"
        // },
        data: [
            {
                // Change type to "doughnut", "line", "splineArea", etc.
                type: "column",
                dataPoints:
          adminDash &&
          adminDash.map((val) => {
              return { label: months[val.month], y: val.totalAmount  };
          }),
            },
        ],
    };
    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
}

export default Linecharttwo;
