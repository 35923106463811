import React from "react";
import plus from "../../assets/images/plus.png";
import { Link } from "react-router-dom";

const AddButton = ({route}) => {
  return (
    <Link to={route}>
      <button type="button" className="add-mob-btn" title="">
        <span>
          <img src={plus} alt="" />
        </span>
      </button>
    </Link>
  );
};

export default AddButton;
