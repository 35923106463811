import COMMON_ACTIONS from "../actions";

const initialState = {
    user: {},
    loadingUser: false,
    propertiesList: [],
    propertiesCount: 0,
    carsList: [],
    carsCount: 0,
    categories: [],
    subCategories: [],
    selectedImage: [],
    images: [],
    car: {},
    property: {},
    propertyLoader: true,
    carLoader: true,
    toursList: [],
    toursCount: 0,
    tour: {},
    currency: "",
    loadingPropertyList: false,
    loadingCarList: false,
    loadingTourList: false,
    socket: {},
    chatList: [],
    chatListCount: 0,
    roomMessages: [],
    roomMessagesCount: 0,
    disableChat: false,
    messagesPagination: {
        offset: 0,
        limit: 12,
    },
    reservationMessagesPagination: {
        offset: 0,
        limit: 12,
    },
    latitude: null,
    longitude: null,
    timezone: null,
    isLastMessage: false,
    room: {},
    notifications: [],
    notificationCount: 0,
    unreadNotificationsCount: 0,
    notificationPagination: {
        offset: 0,
        limit: 10,
    },
    bedCategories: [],
    propertyAmenities: [],
    invoiceListLoader: false,
    invoices: [],
    invoicesCount: 0,
    bookingInvoices: [],
    bookingInvoicesCount: 0,
    requestBookingInvoiceLoader: false,
    notificationTab: {
        notifications: [],
        count: 0,
    },
    recommendedPropertiesPagination: {
        offset: 0,
        limit: 6,
    },
    chatLoader:  true,
    airbnbProperty: {},
    airbnbPropertyLoader: false
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMON_ACTIONS.REQUEST_PROPERTY_LIST:
            return {
                ...state,
                loadingPropertyList: action.status,
            }
        case COMMON_ACTIONS.REQUEST_TOUR_LIST:
            return {
                ...state,
                loadingTourList: action.status,
            };

        case COMMON_ACTIONS.REQUEST_CAR_LIST:
            return {
                ...state,
                loadingCarList: action.status,
            }

        case COMMON_ACTIONS.USER_DETAILS_SUCCESS:
            return {
                ...state,
                user: action.user,
                loadingUser: false,
            };

        case COMMON_ACTIONS.LOADING_USER_DETAILS:
            return {
                ...state,
                loadingUser: action.loading,
            };

        case COMMON_ACTIONS.PROPERTIES_LIST_SUCCESS:
            return {
                ...state,
                propertiesList: action.properties,
                propertiesCount: action.count,
            };

        case COMMON_ACTIONS.CAR_LIST_SUCCESS:
            return {
                ...state,
                carsList: action.cars,
                carsCount: action.count,
            };

        case COMMON_ACTIONS.CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                categories: action.categories,
            };

        case COMMON_ACTIONS.SUB_CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                subCategories: action.subCategories,
            };

        case COMMON_ACTIONS.SET_IMAGES:
            return {
                ...state,
                [action.key]: action.value,
            };

        case COMMON_ACTIONS.SET_SPECIFIC_CAR:
            return {
                ...state,
                car: action.car,
            }

        case COMMON_ACTIONS.REQUEST_SPECIFIC_PROPERTY:
            return {
                ...state,
                propertyLoader: action.status,
            };

        case COMMON_ACTIONS.REQUEST_SPECIFIC_CAR:
            return {
                ...state,
                carLoader: action.status,
            };

        case COMMON_ACTIONS.SET_SPECIFIC_PROPERTY:
            return {
                ...state,
                property: action.property,
            }

        case COMMON_ACTIONS.TOURS_LIST_SUCCESS:
            return {
                ...state,
                toursCount: action.count,
                toursList: action.tours,
                loadingTourList: false,
            };

        case COMMON_ACTIONS.SET_CURRENCY:
            return {
                ...state,
                currency: action.currency,
            };

        case COMMON_ACTIONS.SET_SPECIFIC_TOUR:
            return {
                ...state,
                tour: action.tour,
            };

        case COMMON_ACTIONS.SET_USER_SOCKET:
            return {
                ...state,
                socket: action.socket,
            };

        case COMMON_ACTIONS.SET_USER_ROOMS:
            return {
                ...state,
                chatList: action.chatList,
                chatListCount: action.count,
            };

        case COMMON_ACTIONS.SET_USER_ROOMS_LOADER:
            return {
                ...state,
                chatLoader: action.status,
            };

        case COMMON_ACTIONS.SET_SPECIFIC_ROOM_MESSAGES:
            return {
                ...state,
                roomMessages: [
                    ...action.roomMessages,
                    ...state.roomMessages,
                ],
                roomMessagesCount: action.roomMessagesCount,
                disableChat: action.disableChat,
            };

        case COMMON_ACTIONS.RESET_MESSAGES_PAGINATION:
            return {
                ...state,
                messagesPagination: { offset: 0, limit: 12 },
                isLastMessage: false,
            };

        case COMMON_ACTIONS.SET_MESSAGES_PAGINATION:
            return {
                ...state,
                messagesPagination: {
                    offset: state.messagesPagination.offset + state.messagesPagination.limit,
                    limit: state.messagesPagination.limit,
                }
            };

        case COMMON_ACTIONS.SET_USER_LOCATION:
            return {
                ...state,
                latitude: action.latitude,
                longitude: action.longitude,
                timezone: action.timezone,
            };

        case COMMON_ACTIONS.SET_SPECIFIC_ROOM:
            return {
                ...state,
                room: action.room,
            };

        case COMMON_ACTIONS.SET_RESERVATION_MESSAGES_PAGINATION:
            return {
                ...state,
                reservationMessagesPagination: {
                    offset: state.reservationMessagesPagination.offset + state.reservationMessagesPagination.limit,
                    limit: state.reservationMessagesPagination.limit,
                }
            };

        case COMMON_ACTIONS.SET_LAST_MESSAGE:
            return {
                ...state,
                isLastMessage: action.status,
            };

        case COMMON_ACTIONS.SET_NOTIFICATIONS_TAB:
            return {
                ...state,
                notifications: [...state.notifications, ...action.notifications],
                notificationCount: action.notificationCount,
                unreadNotificationsCount: action.unreadNotificationsCount
            };

        case COMMON_ACTIONS.SET_NOTIFICATIONS:
            return {
                ...state,
                notificationTab: {
                    notifications: action.notifications,
                    count: action.notificationCount
                }
            };
        case COMMON_ACTIONS.SET_NOTIFICATION_PAGINATION:
            return {
                ...state,
                notificationPagination: {
                    offset: state.notificationPagination.offset + state.notificationPagination.limit,
                    limit: state.notificationPagination.limit,
                }
            };

        case COMMON_ACTIONS.SET_BED_CATEGORIES:
            return {
                ...state,
                bedCategories: action.bedCategories,
            };

        case COMMON_ACTIONS.SET_PROPERTY_AMENITIES:
            return {
                ...state,
                propertyAmenities: action.amenities,
            };

        case COMMON_ACTIONS.REQUEST_INVOICE_LIST:
            return {
                ...state,
                invoiceListLoader: action.status,
            };

        case COMMON_ACTIONS.SET_INVOICE_LIST:
            return {
                ...state,
                invoices: action.invoices,
                invoicesCount: action.count,
            };

        case COMMON_ACTIONS.REQUEST_SPECIFIC_INVOICE:
            return {
                ...state,
                requestBookingInvoiceLoader: action.status,
            };

        case COMMON_ACTIONS.SET_SPECIFIC_INVOICE_DETAILS:
            return {
                ...state,
                bookingInvoices: action.invoices,
                bookingInvoicesCount: action.count,
            };

        case COMMON_ACTIONS.SET_RECOMMENDED_PROPERTIES_PAGINATION:
            return {
                ...state,
                recommendedPropertiesPagination: {
                    offset: state.recommendedPropertiesPagination.offset + state.recommendedPropertiesPagination.limit,
                    limit: state.recommendedPropertiesPagination.limit,
                },
            };

        case COMMON_ACTIONS.AIRBNB_PROPERTY_SUCCESS:
            return {
                ...state,
                airbnbProperty: action.data,
            };

        case COMMON_ACTIONS.REQUEST_AIRBNB_PROPERTY:
            return {
                ...state,
                airbnbPropertyLoader: action.status,
            };    

        default:
            return state;
    }
};

export default commonReducer;