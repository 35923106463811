import {
  FormikProvider,
  useFormik,
  Field,
  FieldArray,
  ErrorMessage,
  Formik,
  Form,
} from "formik";
import React, { useEffect, useState, useRef, Fragment } from "react";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import Switch from "react-switch";
import wait from "../../../../assets/images/wait.png";
import { useNavigate } from "react-router-dom";

import {
  Button,
  DropImage,
  MultiSelect,
  Toaster,
} from "../../../../components";
import AppPropDropImage from "../../../../components/DropImage/verndorPropImage"
import {
  AdminCategoryAction,
  AdminCitiesAction,
  AdminPropertyAction,
} from "../../../../store/admin/thunk";
import { connect, useDispatch, useSelector } from "react-redux";
import { AddPropertySchema, AirbnbPropertySchema } from "../../../../schemas";
import {
  CATEGORY_TYPE,
  EDITOR_PLUGINS,
  FILE_UPLOAD_TYPE,
  MESSAGES,
  TOAST_TYPE,
  IMAGE_FILE_TYPES,
  MAXIMUM_FILE_SIZE_LIMIT,
  MINIMUM_FILE_UPLOAD_LIMIT,
  CREATE_PROPERTY_LOADER,
} from "../../../../constants";
import { Axios } from "../../../../lib";
import { FocusError } from "../../../../utils";
import { processImage, uploadImage } from "../../../../utils/imageHelper";
import COMMON_ACTIONS from "../../../../store/common/actions";
import styles from "./style.module.css";
import AddLoader from "../../Loader";
import VENDOR_ACTIONS from "../../../../store/vendor/actions";
import addstyle from "../../../Common/styles/common.module.css";
import AddBuilding from "../../Buildings/Add";
const AddProperty = ({
  loader,
  listCities,
  listCategories,
  listBedCategories,
  listAmenities,
  create,
  airbnbPropertyDetail,
}) => {
  const [getBedCategories, setGetBedCategories] = useState([]);
  const [getBedCategoriesType, setGetBedCategoriesType] = useState([]);
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    categories: { categories },
    locations: { cities },
  } = useSelector((state) => state.admin);
  const {
    user,
    bedCategories,
    propertyAmenities,
    images,
    airbnbProperty,
    airbnbPropertyLoader,
  } = useSelector((state) => state.common);

  const {
    properties: { createPropertyLoader },
  } = useSelector((state) => state.vendor);

  const [areas, setAreas] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedBathroom, setSelectedBathroom] = useState({});
  const [subCategory, setSubCategories] = useState([]);
  const [amenitiesName, setAmenitiesName] = useState([]);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [areaId, setAreaId] = useState("");
  const [selectBuilding, setSelectBuilding] = useState("");

  const [openAmenities, setOpenAmenities] = useState(false);
  const [isAirbnbLink, setIsAirbnbLink] = useState(true);
  const [isAddBuilding, setIsAddBuilding] = useState(false);
  const [initialValues] = useState({
    images: [],
    name: "",
    unitNumber: "",
    city: "",
    area: "",
    building: "",
    category: "",
    subCategory: [],
    price: "",
    floorNumber: "",
    totalBeds: 0,
    beds: [],
    totalBathrooms: 0,
    bathrooms: [],
    personCapacity: "",
    areaInSqft: "",
    description: "",
    maidRoom: false,
    maidRoomBathroomAttached: false,
    driverRoom: false,
    driverRoomBathroomAttached: false,
    amenities: [],
  });

  const openAmenitiesRef = useRef(null);

  const addProperty = async (values) => {
    try {
      if (images?.length < 3) {
        addPropertyFormik.setFieldError(
          "images",
          MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT
        );
        return;
      } else if (images?.length > 100) {
        addPropertyFormik.setFieldError(
          "images",
          MESSAGES.MAXIMUM_UPLOAD_FILE_LIMIT
        );
        return;
      }

      dispatch({
        type: VENDOR_ACTIONS.SET_CREATE_PROPERTY_LOADER,
        status: CREATE_PROPERTY_LOADER.UPLOAD_IMAGE,
      });

      dispatch({
        type: VENDOR_ACTIONS.SET_CREATE_PROPERTY_LOADER,
        status: CREATE_PROPERTY_LOADER.CREATE_PROPERTY,
      });

      const buildingDetails = buildings.find(
        (item) => item._id === values.building
      );

      const params = {
        city_id: values.city,
        category_id: values.category,
        subCategory_ids: values.subCategory.map((item) => item.value),
        name: values.name,
        description: values.description,
        price: values.price,
        floorNumber: values.floorNumber,
        latitude: buildingDetails.latitude,
        longitude: buildingDetails.longitude,
        personCapacity: values.personCapacity,
        bedrooms: values.bedrooms,
        bathRooms: values.bathRooms,
        images: images,
        amenities: values.amenities,
        areaInSqft: values.areaInSqft,
        currency: "AED",
        otherRoom: {
          driverRoom: values.driverRoom,
          maidRoom: values.maidRoom,
          driverRoomBathRoom: values.driverRoomBathroomAttached,
          maidRoomBathRoom: values.maidRoomBathroomAttached,
        },
        areaId: values.area,
        buildingId: values.building,
        unitNumber: values.unitNumber,
      };
      console.log(params, " params 5454545 45454 54")
      create(params, navigate, loader);
    } catch (error) {
      dispatch({
        type: VENDOR_ACTIONS.SET_CREATE_PROPERTY_LOADER,
        status: null,
      });
      console.error("ERROR-->", error);
    }
  };

  const handleValidate = async () => {
    const errors = {};
    let fileImages = [];
    for (let i = 0; i < images?.length; i++) {
      if (images[i] instanceof File) {
        const result = processImage(images[i]);
        fileImages.push(result);
      }
    }

    const processedImages = await Promise.all(fileImages);
    const invalidAspectRatio = processedImages?.filter(
      (item) => item.is4by3 === false
    );
    const invalidFileTypes = processedImages?.filter(
      (item) => !IMAGE_FILE_TYPES.includes(item.file.type)
    );
    const invalidOldFileSize = images?.filter(
      (item) => item.size > MAXIMUM_FILE_SIZE_LIMIT
    );
    const invalidNewFileSize = processedImages?.filter(
      (item) => item.file.size > MAXIMUM_FILE_SIZE_LIMIT
    );

    if (images?.length < MINIMUM_FILE_UPLOAD_LIMIT) {
      errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
    } else if (invalidAspectRatio?.length) {
      errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
    } else if (invalidFileTypes?.length) {
      errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
    } else if (invalidNewFileSize?.length || invalidOldFileSize?.length) {
      errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
    }

    return errors;
  };

  const addPropertyFormik = useFormik({
    initialValues: initialValues,
    validationSchema: AddPropertySchema,
    enableReinitialize: true,
    // validate: handleValidate,

    onSubmit: (values, { setFieldError }) => {
      if (values.description === "")
        return setFieldError("description", "Required");
      let payload = values;
      payload.bedrooms = [];
      payload.bathRooms = [];

      payload.amenities = selectedAmenities
        .filter((item) => item.checked)
        .map((item) => ({
          _id: item.value,
          isSpecial: item.special ? item.special : false,
        }));

      for (let bed of payload.beds) {
        const items = bed.items.map((item) => ({
          numberOfBeds: parseInt(item.totalBeds, 10),
          bedType: item.bedType,
          bedRoomNumber: bed.bedRoomName,
        }));
        payload.bedrooms.push(...items);
      }

      for (let bathroom of payload.bathrooms) {
        let obj = {};

        if (bathroom.private === "true") {
          obj.bathRoomPrivate = true;
          obj.bathRoomEnsuite = false;
          obj.insideAppartment = false;
        } else obj.bathRoomPrivate = false;

        if (bathroom.ensuite === "true") {
          obj.bathRoomEnsuite = true;
        } else if (obj.bathRoomPrivate) {
          obj.insideAppartment = false;
          obj.bathRoomEnsuite = false;
        }
        if (bathroom.insideBathroom === "true") obj.insideAppartment = true;

        if (bathroom.bedroom) {
          obj.bedRoomNumber = bathroom.bedroom;
          delete obj.insideAppartment;
        }

        payload.bathRooms.push(obj);
console.log(payload, " payloaad 544545 454 ")
        addProperty(payload);
      }
    },
  });

  const handleCityChange = (event) => {
    addPropertyFormik.setFieldValue(event.target.name, event.target.value);

    const specificCity = cities.find((item) => item._id === event.target.value);

    if (specificCity) {
      const areas = specificCity.areaData.map((item) => ({
        _id: item._id,
        name: item.name,
        buildings: item.buildingData,
      }));

      setAreas(areas);
      setBuildings([]);
      addPropertyFormik.setFieldValue("building", "");
    } else {
      setAreas([]);
      setBuildings([]);
    }
  };

  const handleAreaChange = (event) => {
    addPropertyFormik.setFieldValue(event.target.name, event.target.value);

    const specificArea = areas.find((item) => item._id === event.target.value);
    setAreaId(event.target.value);
    // setDisabledLocations({
    //   ...disabledLocations,
    //   area: specificArea ? specificArea.status === 'INACTIVE' : false,
    //   building: false,
    // });

    if (specificArea) {
      const buildings = specificArea.buildings.map((item) => ({
        _id: item._id,
        name: item.name,
        latitude: item.latitude,
        longitude: item.longitude,
      }));

      setBuildings([
        { _id: "add_building", name: "Add New building" },
        ...buildings,
      ]);
    } else setBuildings([]);
  };

  const handleCategoryChange = (event) => {
    addPropertyFormik.setFieldValue(event.target.name, event.target.value);

    Axios.get(`/category/${event.target.value}`, {}, loader).then((res) => {
      setSubCategories(res.data.sub_categories);
    });
  };

  const handleAmnitiesChange = (event) => {
    let tempAmnities = [...propertyAmenities];
    let index = tempAmnities.findIndex(
      (item) => item.value === event.target.value
    );

    if (index >= 0) {
      tempAmnities[index].checked = !tempAmnities[index].checked;
      tempAmnities[index].special = false;

      const tempAmnitiesName = tempAmnities
        .filter((item) => item.checked === true)
        .map((item) => item.label);
      tempAmnities = tempAmnities.filter((item) => item.checked === true);

      setSelectedAmenities(tempAmnities);
      addPropertyFormik.setFieldValue("amenities", tempAmnities);
      setAmenitiesName(tempAmnitiesName);
    }
  };

  const handleSpecialAmenitiesChange = (value) => {
    let tempAmnities = [...propertyAmenities];

    let index = tempAmnities.findIndex((item) => item.value === value);

    if (index >= 0) {
      tempAmnities[index].checked = true;
      tempAmnities[index].special = !tempAmnities[index].special;
      const tempAmnitiesName = tempAmnities
        .filter((item) => item.checked === true)
        .map((item) => item.label);
      tempAmnities = tempAmnities.filter((item) => item.checked === true);

      setSelectedAmenities(tempAmnities);
      addPropertyFormik.setFieldValue("amenities", tempAmnities);
      setAmenitiesName(tempAmnitiesName);
    }
  };

  useEffect(() => {
    addPropertyFormik.setFieldValue("name", airbnbProperty?.name);
    addPropertyFormik.setFieldValue(
      "personCapacity",
      airbnbProperty?.personCapacity
    );
    addPropertyFormik.setFieldValue("description", airbnbProperty?.description);

    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: airbnbProperty?.images,
    });

    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "images",
      value: airbnbProperty?.images,
    });

    const amenities = propertyAmenities
      .map((val) => {
        airbnbProperty?.amenities?.find((obj) => obj._id === val.value)
          ? (val.checked = true)
          : null;
        return val;
      })
      ?.filter((val) => val);

    dispatch({
      type: COMMON_ACTIONS.SET_PROPERTY_AMENITIES,
      amenities: amenities,
    });

    setSelectedAmenities(amenities);
    addPropertyFormik.setFieldValue("amenities", amenities);
    setAmenitiesName(
      amenities?.filter((val) => val.checked)?.map((item) => item.label)
    );
  }, [airbnbProperty]);

  useEffect(() => {
    return () => {
      dispatch({
        type: COMMON_ACTIONS.AIRBNB_PROPERTY_SUCCESS,
        data: {},
      });
      dispatch({
        type: COMMON_ACTIONS.SET_PROPERTY_AMENITIES,
        amenities: [],
      });
    };
  }, []);

  const AirbnbLinkPopup = () => {
    return (
      <div
        className={`modal fade ${isAirbnbLink ? "show" : "hide"}`}
        id="calendar-price"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add an Airbnb link to add the property.
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsAirbnbLink(false)}
              ></button>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{ airbnbLink: "" }}
                validationSchema={AirbnbPropertySchema}
                onSubmit={async (values, { setSubmitting }) => {
                  airbnbPropertyDetail(values, loader);
                  setSubmitting(false);
                  setIsAirbnbLink(false);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <div className="mb-3">
                      <label htmlFor="airbnbLink" className="form-label">
                        Airbnb Link
                      </label>
                      <Field
                        type="text"
                        name="airbnbLink"
                        className="form-control"
                        placeholder="Airbnb Link"
                      />
                      <ErrorMessage
                        name="airbnbLink"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const AddNewBuildingPopup = () => {
    return (
      <div
        className={`modal fade ${isAddBuilding ? "show" : "hide"}`}
        id="calendar-price"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Building
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setIsAddBuilding(false)}
              ></button>
            </div>
            <div className="modal-body">
              <AddBuilding
                setBuildings={setBuildings}
                areaId={areaId}
                setIsAddBuilding={setIsAddBuilding}
                setSelectBuilding={setSelectBuilding}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const openAmenitiesBox = () => setOpenAmenities(!openAmenities);

  useEffect(() => {
    if (user && user.userType) {
      const params = {
        showAllCities: true,
      };

      const categoriesParams = {
        type: CATEGORY_TYPE.PROPERTY,
        showAllCategories: true,
      };

      listCities(params, loader, user.userType);
      listCategories(categoriesParams, loader);
      listBedCategories(loader);
      listAmenities(loader);

      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "selectedImage",
        value: [],
      });
      dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [] });
    }
  }, [user]);

  const getSingleBedCategoriesType = (beds, items) => {
    const btypeIds = getBedCategoriesType
      ?.filter((b) => b.beds == beds && b.items != items)
      ?.map((b) => b.bedType);
    return getBedCategories.filter((a) => !btypeIds.includes(a._id));
  };

  useEffect(() => {
    addPropertyFormik.setFieldValue("building", selectBuilding);
  }, [buildings, selectBuilding]);

  return (
    <>
      {airbnbPropertyLoader && (
        <div className={styles.waiOuter}>
          <div className={styles.waitPop}>
            <img src={wait} alt="" />
            <h5>{MESSAGES.AIR_BNB_LOADING}</h5>
          </div>
        </div>
      )}
      <AirbnbLinkPopup />

      <AddNewBuildingPopup />
      <div className={addstyle["add-property-sc"]}>
        <FormikProvider value={addPropertyFormik}>
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-12">
                <div
                  className={` ${addstyle["form-group"]} ${addstyle["add-image"]} ${addstyle["new-add-gallery"]}`}
                >
                  <AppPropDropImage formik={addPropertyFormik} />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <label>Property Title</label>
                  <Field name="name" placeholder="Enter name" />
                  {addPropertyFormik.errors.name &&
                  addPropertyFormik.touched.name ? (
                    <div className="error">{addPropertyFormik.errors.name}</div>
                  ) : null}
                </div>
                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <label>Unit Number</label>
                  <Field name="unitNumber" placeholder="Enter unit number" />
                  {addPropertyFormik.errors.unitNumber &&
                  addPropertyFormik.touched.unitNumber ? (
                    <div className="error">
                      {addPropertyFormik.errors.unitNumber}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <div className="d-flex justify-content-between">
                    <label>Select city</label>
                    {/* {disabledLocations.city ? <label className="disabled-locations">Selected city is not active</label> : null} */}
                  </div>

                  <Field as="select" name="city" onChange={handleCityChange}>
                    <option value="">click to select</option>
                    {cities.map((city) => (
                      <option key={city._id} value={city._id}>
                        {city.name}
                      </option>
                    ))}
                  </Field>

                  {addPropertyFormik.errors.city &&
                  addPropertyFormik.touched.city ? (
                    <div className="error">{addPropertyFormik.errors.city}</div>
                  ) : null}
                </div>

                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <div className="d-flex justify-content-between">
                    <label>Select Area</label>
                    {/* {disabledLocations.area ? <label className="disabled-locations">Selected area is not active</label> : null} */}
                  </div>
                  <Field onChange={handleAreaChange} as="select" name="area">
                    <option value="">click to select</option>
                    {areas.map((area) => (
                      <option key={area._id} value={area._id}>
                        {area.name}
                      </option>
                    ))}
                  </Field>
                  {addPropertyFormik.errors.area &&
                  addPropertyFormik.touched.area ? (
                    <div className="error">{addPropertyFormik.errors.area}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <div className="d-flex justify-content-between">
                    <label>Select Building</label>
                    {/* {disabledLocations.building ? <label className="disabled-locations">Selected building is not active</label> : null} */}
                  </div>
                  <Field
                    onChange={(e) => {
                      // const building = buildings.find(item => item._id === e.target.value);

                      // setDisabledLocations({
                      //   ...disabledLocations,
                      //   building: building ? building.status === 'INACTIVE' : false,
                      // });

                      if (e.target.value === "add_building") {
                        setIsAddBuilding(true);
                        return false;
                      }
                      setSelectBuilding(e.target.value);
                    }}
                    as="select"
                    name="building"
                  >
                    <option value="">click to select</option>
                    {buildings.map((building) => (
                      <option key={building._id} value={building._id}>
                        {building.name}
                      </option>
                    ))}
                  </Field>
                  {addPropertyFormik.errors.building &&
                  addPropertyFormik.touched.building ? (
                    <div className="error">
                      {addPropertyFormik.errors.building}
                    </div>
                  ) : null}
                </div>

                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <div className="d-flex justify-content-between">
                    <label>Select category</label>
                    {/* {disabledLocations.category ? <label className="disabled-locations">Selected category is not active</label> : null} */}
                  </div>
                  <Field
                    onChange={handleCategoryChange}
                    as="select"
                    name="category"
                  >
                    <option value="">click to select</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </Field>
                  {addPropertyFormik.errors.category &&
                  addPropertyFormik.touched.category ? (
                    <div className="error">
                      {addPropertyFormik.errors.category}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <div className="d-flex justify-content-between">
                    <label>Select sub category</label>
                    {/* {disabledLocations.subCategory ? <label className="disabled-locations">Selected sub category is not active</label> : null} */}
                  </div>

                  <Field
                    name="subCategory"
                    id="multiSelectCustom"
                    placeholder="Select sub-categories"
                    isMulti={true}
                    component={MultiSelect}
                    defaultOption={addPropertyFormik.values.subCategory}
                    options={subCategory.map((val) => ({
                      label: val.name,
                      value: val._id,
                    }))}
                  />

                  {addPropertyFormik.errors.subCategory &&
                  addPropertyFormik.touched.subCategory ? (
                    <div className="error">
                      {addPropertyFormik.errors.subCategory}
                    </div>
                  ) : null}
                </div>

                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <label>Enter price</label>
                  <Field
                    min={1}
                    name="price"
                    type="text"
                    placeholder="Enter price/day"
                    // disabled={!!property}
                    // pattern="[0-9]*"
                    // validate={(value) => {
                    //   if (!/^\d*$/.test(value)) {
                    //     return "Please enter a whole number for the price.";
                    //   }
                    //   return "";
                    // }}
                  />
                  {addPropertyFormik.errors.price &&
                  addPropertyFormik.touched.price ? (
                    <div className="error">
                      {addPropertyFormik.errors.price}
                    </div>
                  ) : null}
                </div>

                <div className={`col-md-6 ${addstyle["form-group"]}`}>
                  <label>Enter Floor Number</label>
                  <Field
                    name="floorNumber"
                    type="text"
                    placeholder="Enter Floor Number"
                    // pattern="[0-9]*"
                    // validate={(value) => {
                    //   if (!/^\d*$/.test(value)) {
                    //     return "Please enter a whole number for the price.";
                    //   }
                    //   return "";
                    // }}
                  />
                  {addPropertyFormik.errors.floorNumber &&
                  addPropertyFormik.touched.floorNumber ? (
                    <div className="error">
                      {addPropertyFormik.errors.floorNumber}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div
              className={`col-md-12 form-group ${styles["bathroom-select"]}`}
            >
              <h5>Bedrooms Options</h5>
              <hr />
              <label>Total Bedrooms</label>
              <Field
                min={1}
                name="totalBeds"
                as="select"
                value={addPropertyFormik.values.totalBeds}
                onChange={(event) => {
                  const { name, value } = event.target;
                  let newArr = [...addPropertyFormik.values.beds];

                  if (parseInt(value, 10) > newArr?.length) {
                    const newLength = parseInt(value, 10) - newArr?.length;

                    for (let i = 0; i < newLength; i++) {
                      newArr.push({
                        bedRoomName: i + 1,
                        items: [{ bedType: "", totalBeds: 0 }],
                      });
                    }
                  } else {
                    let toDelete = newArr?.length - parseInt(value, 10);

                    while (toDelete--) {
                      newArr.pop();
                    }
                  }

                  addPropertyFormik.setFieldValue(name, value);
                  addPropertyFormik.setFieldValue("beds", newArr);
                  setGetBedCategories(bedCategories);
                }}
              >
                <option value={0}>Click to select</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </Field>
              {addPropertyFormik.errors.totalBeds &&
              addPropertyFormik.touched.totalBeds ? (
                <div className="error">
                  {addPropertyFormik.errors.totalBeds}
                </div>
              ) : null}

              <FieldArray
                name="beds"
                render={() => (
                  <div>
                    {addPropertyFormik.values.beds.map((bed, index) => (
                      <div className={styles["bathrom-options"]} key={index}>
                        <div>
                          <h6>Bedroom {index + 1} </h6>
                          <FieldArray
                            name={`beds.${index}.items`}
                            render={(innerArrayHelper) => (
                              <div>
                                {bed.items.map((innerItem, innerIndex) => (
                                  <div key={innerIndex}>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className={addstyle["form-group"]}>
                                          <label>
                                            What kind of beds are available?
                                          </label>
                                          <div>
                                            <Field
                                              as="select"
                                              name={`beds.${index}.items.${innerIndex}.bedType`}
                                              onChange={(event) => {
                                                const { name, value } =
                                                  event.target;
                                                setGetBedCategoriesType(
                                                  (pre) => {
                                                    // Check if the value already exists
                                                    const existingIndex =
                                                      pre.findIndex(
                                                        (item) =>
                                                          item.beds === index &&
                                                          item.items ===
                                                            innerIndex
                                                      );

                                                    if (existingIndex !== -1) {
                                                      // If it exists, update the existing value
                                                      return pre.map(
                                                        (item, idx) =>
                                                          idx === existingIndex
                                                            ? {
                                                                ...item,
                                                                bedType: value,
                                                              }
                                                            : item
                                                      );
                                                    } else {
                                                      // If it doesn't exist, add a new object
                                                      return [
                                                        ...pre,
                                                        {
                                                          beds: index,
                                                          items: innerIndex,
                                                          bedType: value,
                                                        },
                                                      ];
                                                    }
                                                  }
                                                );

                                                addPropertyFormik.setFieldValue(
                                                  name,
                                                  value
                                                );
                                              }}
                                              value={innerItem.bedType}
                                              onBlur={() => {}}
                                            >
                                              <option value="">
                                                Select a bed type
                                              </option>
                                              {getSingleBedCategoriesType(
                                                index,
                                                innerIndex
                                              ).map((item, key) => (
                                                <option
                                                  key={key}
                                                  value={item._id}
                                                >
                                                  {item.name}
                                                </option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              className="error"
                                              name={`beds.${index}.items.${innerIndex}.bedType`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className={addstyle["form-group"]}>
                                          <label>Number of beds</label>
                                          <div>
                                            <Field
                                              as="select"
                                              name={`beds.${index}.items.${innerIndex}.totalBeds`}
                                              onChange={(event) => {
                                                const { name, value } =
                                                  event.target;
                                                addPropertyFormik.setFieldValue(
                                                  name,
                                                  value
                                                );
                                              }}
                                              value={innerItem.totalBeds}
                                              onBlur={() => {}}
                                            >
                                              <option value={0}>
                                                Select number of beds
                                              </option>
                                              <option value={1}>1</option>
                                              <option value={2}>2</option>
                                              <option value={3}>3</option>
                                            </Field>
                                            <ErrorMessage
                                              component="div"
                                              className="error"
                                              name={`beds.${index}.items.${innerIndex}.totalBeds`}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      {bed.items?.length === innerIndex + 1 ? (
                                        <div className={addstyle["form-group"]}>
                                          <span
                                            className={
                                              styles["add-onother-nbed"]
                                            }
                                            onClick={() => {
                                              innerArrayHelper.push({
                                                bedType: "",
                                                totalBeds: 0,
                                              });
                                            }}
                                          >
                                            <i className="fas fa-plus-circle"></i>
                                            Add another bed
                                          </span>
                                        </div>
                                      ) : null}
                                      {bed.items?.length !== 1 ? (
                                        <span
                                          className={styles["add-onother-nbed"]}
                                          onClick={() => {
                                            innerArrayHelper.remove(innerIndex);

                                            setGetBedCategoriesType((pre) => {
                                              // Check if the value already exists
                                              const existingIndex =
                                                pre.length > 0
                                                  ? pre.findIndex(
                                                      (item) =>
                                                        item.beds === index &&
                                                        item.items ===
                                                          innerIndex
                                                    )
                                                  : -1;

                                              if (existingIndex !== -1) {
                                                if (pre.length > 0) {
                                                  const getVal = pre.filter(
                                                    (_, idx) =>
                                                      idx !== existingIndex
                                                  );
                                                  return getVal.map((list) => {
                                                    if (
                                                      list.beds === index &&
                                                      list.items > innerIndex
                                                    ) {
                                                      list.items =
                                                        list.items - 1;
                                                    }
                                                    return list;
                                                  });
                                                }
                                              }
                                              return pre;
                                            });
                                          }}
                                        >
                                          <i className="fas fa-minus-circle"></i>
                                          Remove bed
                                        </span>
                                      ) : null}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>

            <div
              className={`col-md-12 form-group bathroom-select ${styles["bathroom-select"]}`}
            >
              <h5>Bathroom Options</h5>
              <hr />
              <label>Total Bathrooms</label>
              <Field
                min={1}
                name="totalBathrooms"
                as="select"
                value={addPropertyFormik.values.totalBathrooms}
                onChange={(event) => {
                  const { name, value } = event.target;
                  let newArr = [...addPropertyFormik.values.bathrooms];

                  if (parseInt(value, 10) > newArr?.length) {
                    const newLength = parseInt(value, 10) - newArr?.length;

                    for (let i = 0; i < newLength; i++) {
                      newArr.push({
                        private: null,
                        ensuite: null,
                        bedroom: "",
                        insideBathroom: null,
                      });
                    }
                  } else {
                    let toDelete = newArr?.length - parseInt(value, 10);

                    while (toDelete--) {
                      newArr.pop();
                    }
                  }

                  addPropertyFormik.setFieldValue(name, value);
                  addPropertyFormik.setFieldValue("bathrooms", newArr);
                }}
              >
                <option value={0}>Click to select</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
              </Field>
              {addPropertyFormik.errors.totalBathrooms &&
              addPropertyFormik.touched.totalBathrooms ? (
                <div className="error">
                  {addPropertyFormik.errors.totalBathrooms}
                </div>
              ) : null}

              <FieldArray
                name="bathrooms"
                render={() => (
                  <div>
                    {addPropertyFormik.values.bathrooms.map((item, index) => (
                      <div className={styles["bathrom-options"]} key={index}>
                        <div>
                          <h6>Bathroom {index + 1}</h6>
                          <div className={addstyle["form-group"]}>
                            <label>
                              Is the bathroom private ? (not shared with host or
                              other guests)
                            </label>
                            <div>
                              <span>
                                <Field
                                  type="radio"
                                  name={`bathrooms.${index}.private`}
                                  value="true"
                                  className="w-auto h-auto"
                                />{" "}
                                Yes
                              </span>
                              <span>
                                <Field
                                  type="radio"
                                  name={`bathrooms.${index}.private`}
                                  value="false"
                                  className="w-auto h-auto"
                                  onChange={(event) => {
                                    const { name, value } = event.target;

                                    if (value === "false") {
                                      addPropertyFormik.setFieldValue(
                                        `bathrooms.${index}.ensuite`,
                                        false
                                      );
                                      addPropertyFormik.setFieldValue(
                                        `bathrooms.${index}.bedroom`,
                                        false
                                      );
                                    }

                                    addPropertyFormik.setFieldValue(
                                      name,
                                      value
                                    );
                                  }}
                                />{" "}
                                No
                              </span>
                            </div>
                            <ErrorMessage
                              component="div"
                              className="error"
                              name={`bathrooms.${index}.private`}
                            />
                          </div>
                          {item.private === "true" ? (
                            <div className={addstyle["form-group"]}>
                              <label>
                                Is the bathroom ensuite ? (attached to or inside
                                the bedroom)
                              </label>
                              <div>
                                <span>
                                  <Field
                                    type="radio"
                                    name={`bathrooms.${index}.ensuite`}
                                    value="true"
                                  />{" "}
                                  Yes
                                </span>
                                <span>
                                  <Field
                                    type="radio"
                                    name={`bathrooms.${index}.ensuite`}
                                    value="false"
                                    onChange={(event) => {
                                      const { name, value } = event.target;

                                      addPropertyFormik.setFieldValue(
                                        `bathrooms.${index}.insideBathroom`,
                                        ""
                                      );

                                      if (value === "false") {
                                        addPropertyFormik.setFieldValue(
                                          `bathrooms.${index}.bedroom`,
                                          ""
                                        );
                                      }
                                      addPropertyFormik.setFieldValue(
                                        name,
                                        value
                                      );
                                    }}
                                  />{" "}
                                  No
                                </span>
                              </div>
                              <ErrorMessage
                                component="div"
                                className="error"
                                name={`bathrooms.${index}.ensuite`}
                              />
                            </div>
                          ) : null}
                          {item.ensuite === "true" ? (
                            <div className={addstyle["form-group"]}>
                              <label>Which bedroom is it attached to?</label>
                              <div>
                                <Field
                                  as="select"
                                  name={`bathrooms.${index}.bedroom`}
                                  onChange={(event) => {
                                    const { name, value } = event.target;

                                    addPropertyFormik.setFieldValue(
                                      name,
                                      value
                                    );
                                    let selectedBathroom = {};
                                    for (const item of addPropertyFormik.values
                                      .bathrooms) {
                                      if (item.bedroom)
                                        selectedBathroom[item.bedroom] = true;
                                    }

                                    setSelectedBathroom({
                                      ...selectedBathroom,
                                      [value]: true,
                                    });
                                  }}
                                >
                                  <option value={0}>click to select</option>
                                  {addPropertyFormik.values.beds.map(
                                    (it, index) => (
                                      <option
                                        key={index + 1}
                                        disabled={
                                          selectedBathroom[
                                            (index + 1).toString()
                                          ] || false
                                        }
                                        value={index + 1}
                                      >
                                        Bedroom {index + 1}
                                      </option>
                                    )
                                  )}
                                </Field>
                              </div>
                              <ErrorMessage
                                component="div"
                                className="error"
                                name={`bathrooms.${index}.bedroom`}
                              />
                            </div>
                          ) : null}
                          {item.ensuite === "false" ? (
                            <div className={addstyle["form-group"]}>
                              <label>
                                Is the bathroom inside the apartment ?
                              </label>
                              <div>
                                <span>
                                  <Field
                                    type="radio"
                                    name={`bathrooms.${index}.insideBathroom`}
                                    value="true"
                                  />
                                  Yes
                                </span>
                                <span>
                                  <Field
                                    type="radio"
                                    name={`bathrooms.${index}.insideBathroom`}
                                    value="false"
                                  />
                                  No
                                </span>
                              </div>
                              <ErrorMessage
                                component="div"
                                className="error"
                                name={`bathrooms.${index}.insideBathroom`}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Person Capacity</label>
                  <Field
                    min={1}
                    name="personCapacity"
                    type="text"
                    placeholder="Enter Person Capacity"
                  />
                  {addPropertyFormik.errors.personCapacity &&
                  addPropertyFormik.touched.personCapacity ? (
                    <div className="error">
                      {addPropertyFormik.errors.personCapacity}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 form-group">
                  <label>Area (In SQFT)</label>
                  <Field
                    min={1}
                    name="areaInSqft"
                    type="text"
                    placeholder="Enter Area in SQFT"
                  />
                  {addPropertyFormik.errors.areaInSqft &&
                  addPropertyFormik.touched.areaInSqft ? (
                    <div className="error">
                      {addPropertyFormik.errors.areaInSqft}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-12 form-group">
                  <label>Amenities</label>

                  <div ref={openAmenitiesRef}>
                    <div
                      className={`${styles.aminityi} d-flex bg-white`}
                      onClick={openAmenitiesBox}
                    >
                      {amenitiesName?.length === 0 ? (
                        <span className="align-self-center">
                          Select Amenities
                        </span>
                      ) : null}
                      {amenitiesName.map((item, index) => (
                        <div key={index} className="mr-2">
                          {item}
                        </div>
                      ))}
                    </div>
                    {openAmenities && (
                      <div className={styles["aminity-box"]}>
                        {openAmenities &&
                          propertyAmenities.map((item, index) => (
                            <div
                              className={styles.checkBoxContainer}
                              key={index}
                            >
                              <input
                                className={
                                  item.special ? styles["special-ammenity"] : ""
                                }
                                type="checkbox"
                                checked={item.checked}
                                value={item.value}
                                onChange={handleAmnitiesChange}
                              />
                              <p
                                onClick={() =>
                                  handleSpecialAmenitiesChange(item.value)
                                }
                                className="mb-0 cursor-pointer"
                              >
                                {item.label}
                              </p>
                            </div>
                          ))}
                        <div className="d-flex justify-content-center w-100">
                          <div className="w-25 d-flex align-items-center">
                            <div className="special me-2"></div>
                            <p className="mb-0">Special Amenity</p>
                          </div>
                          <div className="w-25 d-flex align-items-center">
                            <div className="common me-2"></div>

                            <p className="mb-0">Normal Amenity</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {addPropertyFormik.errors.amenities &&
                  addPropertyFormik.touched.amenities ? (
                    <div className="error">
                      {addPropertyFormik.errors.amenities}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group description" data-color-mode="light">
                <label>Enter Description</label>
                <MdEditor
                  plugins={EDITOR_PLUGINS}
                  name="description"
                  value={addPropertyFormik.values.description}
                  renderHTML={(text) => {
                    mdParser.render(text);
                  }}
                  style={{ height: "50vh" }}
                  onChange={({ text }) => {
                    addPropertyFormik.setFieldValue("description", text);
                  }}
                  view={{ menu: true, md: true, html: false }}
                />
                {addPropertyFormik.errors.description &&
                  addPropertyFormik.touched.description && (
                    <div className="error">
                      {addPropertyFormik.errors.description}
                    </div>
                  )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                  <label>Maid Room</label>
                  <Switch
                    onColor="#e89532"
                    onChange={(value) =>
                      addPropertyFormik.setFieldValue("maidRoom", value)
                    }
                    checked={addPropertyFormik.values.maidRoom}
                  />
                </div>

                <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                  <label>Driver Room</label>
                  <Switch
                    onColor="#e89532"
                    onChange={(value) =>
                      addPropertyFormik.setFieldValue("driverRoom", value)
                    }
                    checked={addPropertyFormik.values.driverRoom}
                  />
                </div>
              </div>
            </div>
            <div
              className={`col-md-12 ${
                !addPropertyFormik.values.maidRoom &&
                !addPropertyFormik.values.driverRoom
                  ? "d-none"
                  : ""
              }`}
            >
              <div className="row">
                <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                  {addPropertyFormik.values.maidRoom ? (
                    <Fragment>
                      <label>Is Bathroom Attached</label>
                      <Switch
                        onColor="#e89532"
                        onChange={(value) =>
                          addPropertyFormik.setFieldValue(
                            "maidRoomBathroomAttached",
                            value
                          )
                        }
                        checked={
                          addPropertyFormik.values.maidRoomBathroomAttached
                        }
                      />
                    </Fragment>
                  ) : null}
                </div>

                <div className="col-md-6 form-group d-flex justify-content-between align-items-center">
                  {addPropertyFormik.values.driverRoom ? (
                    <Fragment>
                      <label>Is Bathroom Attached</label>
                      <Switch
                        onColor="#e89532"
                        onChange={(value) =>
                          addPropertyFormik.setFieldValue(
                            "driverRoomBathroomAttached",
                            value
                          )
                        }
                        checked={
                          addPropertyFormik.values.driverRoomBathroomAttached
                        }
                      />
                    </Fragment>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Button
            type="button"
            handleClick={addPropertyFormik.handleSubmit}
            title="Add"
          />
          <FocusError />

          {createPropertyLoader && <AddLoader />}
        </FormikProvider>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  listCities: (payload, loader, userType) =>
    dispatch(AdminCitiesAction.list(payload, loader, userType)),
  listCategories: (payload, loader) =>
    dispatch(AdminCategoryAction.listCategories(payload, loader)),
  listBedCategories: (loader) => dispatch(AdminPropertyAction.listBeds(loader)),
  listAmenities: (loader) =>
    dispatch(AdminPropertyAction.listAmenities(loader)),
  create: (payload, navigate, loader) =>
    dispatch(AdminPropertyAction.create(payload, navigate, loader)),
  airbnbPropertyDetail: (payload, loader) =>
    dispatch(AdminPropertyAction.airbnbPropertyDetail(payload, loader)),
});

export default connect(null, mapDispatchToProps)(AddProperty);
