import React from "react";
import ReactPaginate from "react-paginate";

import styles from "./style.module.css";

const Pagination = ({ count, setPagination, pageSize = 10 }) => {
    const handlePageClick = ({ selected }) => {
        setPagination((prev) => {
            return { ...prev, offset: selected * prev.limit };
        });
    };

    return (
        count && count > pageSize ? (
            <div className="d-flex mt-2">
                <div className={styles.paginationContainer}>
                    <ReactPaginate
                        previousLabel="<"
                        previousLinkClassName={`${styles["pagination-button"]} text-decoration-none`}
                        nextLinkClassName={`${styles["pagination-button"]} text-decoration-none`}
                        nextLabel=">"
                        breakLabel="..."
                        pageCount={Math.ceil(count / pageSize)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={styles.pagination}
                        subContainerClassName={`pages ${styles.pagination}`}
                        activeClassName={styles.active}
                    />
                </div>
            </div>
        ) : null
    );
};

export default Pagination;
