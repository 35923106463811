import React from "react";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

import { ENV_CONFIG } from "../../utils/env";

const GoogleMapComponent = compose(
    withProps({
        googleMapURL:
			`https://maps.googleapis.com/maps/api/js?key=${ENV_CONFIG.GOOGLE_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`,
        loadingElement: <div style={{ height: "100%" }} />,
        containerElement: <div style={{ height: "400px" }} />,
        mapElement: <div style={{ height: "100%" }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap
        defaultZoom={5}
        defaultCenter={{ lat: props.latitudeCenter || 23.8859, lng: props.longitudeCenter || 45.0792 }}
        center={{ lat: props.latitudeCenter || 23.8859, lng: props.longitudeCenter || 45.0792 }}
        options={{
            fullscreenControl: props.fullScreen || false,
            mapTypeControl: props.mapTypeControl || false,
            zoomControl: props.zoomControl || false,
        }}
    >
        <Marker
            draggable={props.draggable || false}
            onDragEnd={props.onDragEnd}
            position={{
                lat: props.positionalLat || 23.8859,
                lng: props.positionalLong || 45.0792
            }}
        />
    </GoogleMap>
));

const GoogleMapBox = (props) => {
    return (
        <GoogleMapComponent {...props} />
    );
};

export default GoogleMapBox;