import { Axios } from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { CommonAction } from "../../common/thunk";
import COMMON_ACTIONS from "../../common/actions";
import { API_ENDPOINTS, MESSAGES, TOAST_TYPE } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import VENDOR_ACTIONS from "../../vendor/actions";

const approve = (payload, loader, pagination) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.APPROVE_PROPERTY, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, `Property ${payload.isApproved ? "approved" : "unapproved"} successfully`);

            dispatch(CommonAction.propertyList(pagination, loader));
        }
        else {
            Toaster(TOAST_TYPE.ERROR, response.error);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROPERTY.APPROVAL_ERROR);
    }
};

const listBeds = (loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.LIST_PROPERTY_BEDS, {}, loader);
        dispatch({ type: COMMON_ACTIONS.SET_BED_CATEGORIES, bedCategories: data });

    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const listAmenities = (loader) => async (dispatch) => {
    try {
        const { data } = await Axios.get(API_ENDPOINTS.LIST_PROPERTY_AMENITIES, {}, loader);
        dispatch({
            type: COMMON_ACTIONS.SET_PROPERTY_AMENITIES,
            amenities: data.map(item => ({ label: item.title, value: item._id, checked: false }))
        });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const create = (payload, navigate, loader) => async (dispatch) => {
    try {
        const { status } = await Axios.post("/property", payload, loader);

        if (status) {
            navigate(APP_ROUTES.PROPERTIES_LIST);
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.PROPERTY.CREATE_SUCCESS);
        }

        dispatch({ type: VENDOR_ACTIONS.SET_CREATE_PROPERTY_LOADER, status: null });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.PROPERTY.CREATE_ERROR);
    }
};

const airbnbPropertyDetail = (query, loader) => async (dispatch) => {
    try {
        dispatch({ type: COMMON_ACTIONS.REQUEST_AIRBNB_PROPERTY, status: true });
        const { data, status } = await Axios.get(API_ENDPOINTS.AIRBNB_PROPERTY, query, loader);

        dispatch({ type: COMMON_ACTIONS.AIRBNB_PROPERTY_SUCCESS, data: data });
        dispatch({ type: COMMON_ACTIONS.REQUEST_AIRBNB_PROPERTY, status: false });

        if(!status){
            Toaster(TOAST_TYPE.WARN, MESSAGES.INVALID_URL);
        };
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
    
};

export {
    approve,
    listBeds,
    listAmenities,
    create,
    airbnbPropertyDetail
}; 