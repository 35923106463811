import { Axios } from "../../../lib";
import { Toaster } from "../../../components/Toaster";
import { API_ENDPOINTS, MESSAGES } from "../../../constants";
import { APP_ROUTES } from "../../../routes";
import { TOAST_TYPE } from "../../../constants";
import ADMIN_ACTIONS from "../actions";

const listCategories = (payload, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.LOADING_CATEGORIES_LIST, status: true });
        const { data } = await Axios.get(API_ENDPOINTS.LIST_CATEGORIES, payload, loader);

        dispatch({ type: ADMIN_ACTIONS.CATEGORIES_LIST_SUCCESS, categories: data.categories, count: data.count });
        dispatch({ type: ADMIN_ACTIONS.LOADING_CATEGORIES_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: ADMIN_ACTIONS.LOADING_CATEGORIES_LIST, status: false });
    }
};

const listSubCategories = (categoryId, loader) => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_ACTIONS.LOADING_SUB_CATEGORIES_LIST, status: true });

        const { data } = await Axios.get(`/category/${categoryId}`, {}, loader);
        dispatch({ type: ADMIN_ACTIONS.SUB_CATEGORIES_LIST_SUCCESS, subCategories: data.sub_categories, count: data.count, activeCategory: data.name });
        dispatch({ type: ADMIN_ACTIONS.LOADING_SUB_CATEGORIES_LIST, status: false });
    }
    catch (err) {
        dispatch({ type: ADMIN_ACTIONS.LOADING_SUB_CATEGORIES_LIST, status: false });
    }
};

const specificCategory = (categoryId, loader) => async (dispatch) => {
    try {
        const { data, status } = await Axios.get(`/category/${categoryId}`, {}, loader);

        if (status) {
            const category = {
                id: data._id,
                name: data.name,
                type: data.type,
                vat: data.vat,
                image: data.image
            };

            dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_CATEGORY, category });
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const specificSubCategory = (categoryId, loader) => async (dispatch) => {
    try {
        const { data, status } = await Axios.get(`/category/subcategory/${categoryId}`, {}, loader);

        if (status) {
            const category = {
                id: data._id,
                name: data.name,
                type: data.type,
                vat: data.vat,
            };

            dispatch({ type: ADMIN_ACTIONS.SET_SPECIFIC_SUB_CATEGORY, category });
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
    }
};

const update = (payload, loader, navigate, params) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.CREATE_UPDATE_CATEGORY, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CATEGORY.UPDATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(APP_ROUTES.CATEGORIES_LIST);
            }
            else {
                dispatch(listCategories(params, loader));
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CATEGORY.UPDATE_ERROR);
    }
};

const updateSubCategory = (payload, loader, navigate, params) => async (dispatch) => {
    try {
        const response = await Axios.patch(API_ENDPOINTS.CREATE_UPDATE_SUB_CATEGORY, payload, loader);

        if (response.status) {
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.SUB_CATEGORIES.UPDATE_SUCCESS);

            if (payload.name || payload.image) {
                navigate(`/categories/${params.categoryId}/subCategories`);
            }
            else {
                dispatch(listSubCategories(params.categoryId, loader));
            }
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CATEGORY.SUB_CATEGORY.UPDATE_ERROR);
    }
};

const createCategory = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.post(API_ENDPOINTS.CREATE_UPDATE_CATEGORY, payload, loader);

        if (response.status) {
            navigate(APP_ROUTES.CATEGORIES_LIST);
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.CATEGORY.CREATE_SUCCESS);
        }

    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CATEGORY.CREATE_ERROR);
    }
};

const createSubCategory = (payload, loader, navigate) => async () => {
    try {
        const response = await Axios.post(API_ENDPOINTS.CREATE_UPDATE_SUB_CATEGORY, payload, loader);

        if (response.status) {
            navigate(`/categories/${payload.category_id}`);
            Toaster(TOAST_TYPE.SUCCESS, MESSAGES.SUB_CATEGORIES.CREATE_SUCCESS);
        }
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.CATEGORY.SUB_CATEGORY.CREATE_ERROR);
    }
};


export {
    listCategories,
    listSubCategories,
    update,
    updateSubCategory,
    createCategory,
    createSubCategory,
    specificCategory,
    specificSubCategory,
};