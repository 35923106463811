import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Field, useFormik, FormikProvider } from "formik";

import { CategorySchema } from "../../../../schemas";
import { AdminCategoryAction } from "../../../../store/admin/thunk";
import { uploadImage } from "../../../../utils/imageHelper";

const AddCategory = ({ loader, create, update, specificCategory }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [image, setImage] = useState(false);

  const {
    categories: { category },
  } = useSelector((state) => state.admin);

  const categoriesFormik = useFormik({
    initialValues: { name: "", type: "", vat: "", image: null },
    validationSchema: CategorySchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      let payload = values;

      if (image) {
        payload.image = image?.data?.fileUrl;
      }

      if (params.id) {
        payload = {
          category_id: params.id,
          name: values.name,
          type: values.type,
        };

        if (image) {
          payload.image = image?.data?.fileUrl;
        }

        update(payload, loader, navigate);
      } else create(payload, loader, navigate);
    },
  });

  useEffect(() => {
    if (params.id) {
      specificCategory(params.id, loader);
    }
  }, [params.id]);

  useEffect(() => {
    if (category.id) {
      categoriesFormik.setValues({
        name: category.name,
        vat: category.vat,
        type: category.type,
        image: category.image,
      });
    }
  }, [category]);

  const uploadCategoryImage = async (event) => {
    categoriesFormik.setFieldValue("image", event.currentTarget.files[0]);
    if (event?.currentTarget?.files[0]) {
      const formData = new FormData();
      formData.append("file", event.currentTarget.files[0]);
      formData.append("type", 1);
      const image = await uploadImage(formData);
      setImage(image);
    }
  };

  return (
    <div className="edit-users-sc">
      <FormikProvider value={categoriesFormik}>
        <div className="form-group">
          <label>Category Image</label>
          <div className="ImageEdit">
            <span>
              <i className="far fa-image"></i>
            </span>
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={(event) => {
                uploadCategoryImage(event);
              }}
            />
            {image?.data?.fileUrl || category?.image ? (
              <img
                src={`https://imagedelivery.net/DFySbGARKFDYSW_EywLfqQ/${image?.data?.fileUrl}/public`}
                alt={image?.data?.fileUrl}
              />
            ) : (
              category?.image && (
                <img src={category?.image} alt={category?.image} />
              )
            )}
          </div>
          {categoriesFormik.errors.image && categoriesFormik.touched.image ? (
            <div className="error">{categoriesFormik.errors.image}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label>Category name</label>
          <Field name="name" placeholder="Category name" />
          {categoriesFormik.errors.name && categoriesFormik.touched.name ? (
            <div className="error">{categoriesFormik.errors.name}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label>Select type</label>
          <Field as="select" name="type">
            <option value="">click to select</option>
            <option value="PROPERTY">Property</option>
            <option value="CAR">Car</option>
            <option value="TOUR">Tour</option>
          </Field>
          {categoriesFormik.errors.type && categoriesFormik.touched.type ? (
            <div className="error">{categoriesFormik.errors.type}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label>Vendor Commission</label>
          <Field
            type="number"
            min="1%"
            max="100%"
            name="vat"
            placeholder="Vendor Commission"
          />
          {categoriesFormik.errors.vat && categoriesFormik.touched.vat ? (
            <div className="error">{categoriesFormik.errors.vat}</div>
          ) : null}
        </div>

        <button onClick={categoriesFormik.handleSubmit} type="button">
          {params.id ? "Update" : "Add"}
        </button>
      </FormikProvider>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  create: (payload, loader, navigate) =>
    dispatch(AdminCategoryAction.createCategory(payload, loader, navigate)),
  update: (payload, loader, navigate) =>
    dispatch(AdminCategoryAction.update(payload, loader, navigate)),
  specificCategory: (payload, loader) =>
    dispatch(AdminCategoryAction.specificCategory(payload, loader)),
});

export default connect(null, mapDispatchToProps)(AddCategory);
