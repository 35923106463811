import React, { Fragment, useEffect, useState } from "react";
import { Field, useFormik, FormikProvider, FieldArray, ErrorMessage } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";

import { AddTourSchema } from "../../../../schemas";
import { CommonAction } from "../../../../store/common/thunk";
import { Button, Toaster } from "../../../../components";
import DropImage from "../../../../components/DropImage";
import MultiSelect from "../../../../components/MultiSelect";
import { VendorTourAction } from "../../../../store/vendor/thunk";
import { CATEGORY_TYPE, EDITOR_PLUGINS, FILE_UPLOAD_TYPE, IMAGE_FILE_TYPES, MAXIMUM_FILE_SIZE_LIMIT, MESSAGES, MINIMUM_FILE_UPLOAD_LIMIT, TOAST_TYPE, } from "../../../../constants";
import { processImage, uploadImage } from "../../../../utils/imageHelper";
import style from "./style.module.css";
import { AdminCitiesAction } from "../../../../store/admin/thunk";
import COMMON_ACTIONS from "../../../../store/common/actions";
import { UpdateTourSchema } from "../../../../schemas/addTourSchema";
import { Axios } from "../../../../lib";
import addstyle from "../../../Common/styles/common.module.css";


const Addtours = ({ loader, categoriesList, subCategoriesList, createTour, citiesList, specificTour, updateTour, updateTourSubCategory, createTourSubCategory, removeTourSubCategory }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const { categories, images, user, currency } = useSelector((state) => state.common);
    const { tours: { tour } } = useSelector((state) => state.vendor);

    const { locations: { cities } } = useSelector((state) => state.admin);
    const [subCategories, setSubCategories] = useState([]);
    const [isAddSubCategory, setIsAddSubCategory] = useState(null);

    useEffect(() => {
        if (id && currency) {
            specificTour({ slug: id, currency }, loader);
        }
    }, [id, currency]);

    const addTour = async (payload) => {


        createTour(payload, loader, navigate);
    };

    const handleValidate = async () => {
        const errors = {};
        let fileImages = [];

        for (let i = 0; i < images.length; i++) {
            if (images[i] instanceof File) {
                const result = processImage(images[i]);
                fileImages.push(result);
            }
        }

        const processedImages = await Promise.all(fileImages);
        const invalidAspectRatio = processedImages.filter(item => item.is4by3 === false);
        const invalidFileTypes = processedImages.filter(item => !IMAGE_FILE_TYPES.includes(item.file.type));
        const invalidOldFileSize = images.filter(item => item.size > MAXIMUM_FILE_SIZE_LIMIT);
        const invalidNewFileSize = processedImages.filter(item => item.file.size > MAXIMUM_FILE_SIZE_LIMIT);

        if (images.length < MINIMUM_FILE_UPLOAD_LIMIT) {
            errors.images = MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT;
        }
        else if (invalidAspectRatio.length) {
            errors.images = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
        }
        else if (invalidFileTypes.length) {
            errors.images = MESSAGES.VALID_IMAGE_FILE_TYPE;
        }
        else if (invalidNewFileSize.length || invalidOldFileSize.length) {
            errors.images = MESSAGES.UPLOAD_FILE_SIZE_LIMIT;
        }

        return errors;
    };

    const tourFormik = useFormik({
        validate: handleValidate,
        validationSchema: id ? UpdateTourSchema : AddTourSchema,
        initialValues: {
            name: "",
            description: "",
            categoryId: "",
            subCategory: [],
            newSubCategory: [],
            subCategorySlots: [],
            city: "",
            images: [],
        },
        onSubmit: async (values) => {
            let uploadedImages = await Promise.all(
                images?.filter(val => typeof val !== "string").map(val => {
                    const formData = new FormData();
                    formData.append("file", val);
                    formData.append("type", FILE_UPLOAD_TYPE.TOUR);
                    return uploadImage(formData);
                })
            );

            let fileError = uploadedImages.find(item => item && item.status === false && item.error === "File too large");

            if (fileError) {
                Toaster(TOAST_TYPE.ERROR, MESSAGES.UPLOAD_FILE_SIZE_LIMIT);
                return;
            }

            uploadedImages = uploadedImages.filter(item => item !== undefined).map((val) => val.data.fileUrl);
            uploadedImages = [...uploadedImages, ...images.filter(item => typeof (item) === "string")];

            const payload = {
                name: values.name,
                images: uploadedImages,
                categoryId: values.categoryId,
                subCategories: values.subCategorySlots.map(item => ({
                    _id: item.subCategoryId,
                    price: item.price,
                    startTime: moment(item.startTime).format("HH:mm"),
                    endTime: moment(item.endTime).format("HH:mm"),
                    maximumPerson: item.maximumPerson,
                    whatToExpect: item.whatToExpect,
                    pickupAndDrop: parseInt(item.pickUpDrop) === 1,
                })),
                description: values.description,
                city_id: values.city,
                currency,
            };

            if (!id) addTour(payload);
            else {

                const updatePayload = {
                    name: values.name,
                    images: uploadedImages,
                    city_id: values.city,
                    description: values.description,
                    tourId: tour._id,
                };

                if (isAddSubCategory) {
                    const whatToExpect = isAddSubCategory.whatToExpect.filter(item => item === "");

                    let error = false;

                    if (isAddSubCategory.price === 0) {
                        tourFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.price`, "Price cannot be zero");
                        error = true;
                    }
                    else if (isAddSubCategory.price === "") {
                        tourFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.price`, "Required");
                        error = true;
                    }

                    if (isAddSubCategory.maximumPerson === "") {
                        tourFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.maximumPerson`, "Required");
                        error = true;
                    }

                    if (whatToExpect.length) {
                        tourFormik.setFieldError(`subCategorySlots.${isAddSubCategory.index}.price`, "Required")
                        error = true;
                    }

                    if (error) return;

                    let payload = {
                        tourId: tour._id,
                        maximumPerson: isAddSubCategory.maximumPerson,
                        startTime: moment(isAddSubCategory.startTime).format("HH:mm"),
                        endTime: moment(isAddSubCategory.endTime).format("HH:mm"),
                        price: isAddSubCategory.price,
                        whatToExpect: isAddSubCategory.whatToExpect,
                        _id: isAddSubCategory.subCategoryId,
                        categoryId: tourFormik.values.categoryId,
                        pickupAndDrop: isAddSubCategory.pickUpDrop === "1",
                    };

                    createTourSubCategory(payload, loader);
                    setIsAddSubCategory(null);
                }
                else updateTour(updatePayload, loader);
            }
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        if (user && user.userType) {
            const cityQuery = { offset: 0, limit: 1000, type: "TOUR" };
            citiesList(cityQuery, loader, user.userType);
        }
    }, [user])


    const handleCityChange = (event) => {
        tourFormik.setFieldValue(event.target.name, event.target.value);
    };

    const addWhatToExpect = (index) => {
        const slots = tourFormik.values.subCategorySlots;
        slots[index].whatToExpect.push("");
        tourFormik.setFieldValue("subCategorySlots", slots);
    };

    const removeWhatToExpect = (index, innerIndex) => {
        let slots = tourFormik.values.subCategorySlots;

        slots[index].whatToExpect.splice(innerIndex, 1);
        tourFormik.setFieldValue("subCategorySlots", slots);
    };

    const removeSubCategory = (values, index) => {
        if (tourFormik.values.subCategorySlots.length === 1) {
            Toaster(TOAST_TYPE.ERROR, MESSAGES.ATLEAST_ONE_TOUR_SUB_CATEGORY);
        }
        else {
            const payload = {
                tourId: tour._id,
                subCategoryId: values.subCategoryId,
            };
            let tempValue = [];

            const specificCategory = tour.categoryData.find(item => item._id === tourFormik.values.categoryId);
            const subCategory = specificCategory.subCategoryData.find(item => item._id === values.subCategoryId);

            tempValue.push({ label: subCategory.name, value: subCategory._id })
            setSubCategories(tempValue);

            const subCategorySlots = tourFormik.values.subCategorySlots
            subCategorySlots.splice(index, 1);
            removeTourSubCategory(payload, loader);
        }
    };

    const updateSubCategory = (values) => {
        const payload = {
            tourId: tour._id,
            subCategoryId: values.subCategoryId,
            whatToExpect: values.whatToExpect,
            startTime: moment(values.startTime).format("HH:mm"),
            endTime: moment(values.endTime).format("HH:mm"),
        };
        updateTourSubCategory(payload, loader);
    };

    const addSubCategory = (item, index) => {
        setIsAddSubCategory({ ...item, index });
        tourFormik.submitForm();
    };

    const listSubCategories = async (value) => {
        let apiUrl = "/category/" + value;
        const { status, data } = await Axios.get(apiUrl);

        if (status) {
            const subCategory = data.sub_categories.map(item => ({ name: item.name, _id: item._id }));
            setSubCategories(subCategory);
        }
        else setSubCategories([]);
    };

    useEffect(() => {
        const query = { type: CATEGORY_TYPE.TOUR };

        categoriesList(query, loader);

        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: [] });
        dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [] });
        tourFormik.resetForm();
    }, []);

    useEffect(() => {
        let newArr = [];

        for (let i = 0; i < tourFormik.values.subCategory.length; i++) {
            const existingSubCategory = tourFormik.values.subCategorySlots.find(item => item.subCategoryId === tourFormik.values.subCategory[i].value);

            let savedSubCategory;

            if (id) {
                let selectedCategory = tour.categoryData.find(item => item.isSelected);
                savedSubCategory = selectedCategory.subCategoryData.find(item => item._id === tourFormik.values.subCategory[i].value);
            }

            if (existingSubCategory) {
                newArr.push({ ...existingSubCategory, new: false });
            }
            else {
                newArr.push({
                    title: savedSubCategory ? savedSubCategory.name : "",
                    name: tourFormik.values.subCategory[i].label,
                    subCategoryId: tourFormik.values.subCategory[i].value,
                    maximumPerson: "",
                    startTime: savedSubCategory ? moment(savedSubCategory.startTime, "HH:mm").toDate() : "",
                    endTime: savedSubCategory ? moment(savedSubCategory.endTime, "HH:mm").toDate() : "",
                    price: savedSubCategory ? savedSubCategory.price : 0,
                    whatToExpect: savedSubCategory ? savedSubCategory.whatToExpect : [""],
                    pickUpDrop: "",
                    new: false,
                });
            }
        }

        tourFormik.setFieldValue("subCategorySlots", newArr);
    }, [tourFormik.values.subCategory]);

    useEffect(() => {
        let newArr = tourFormik.values.subCategorySlots;

        for (let i = 0; i < tourFormik.values.newSubCategory.length; i++) {
            newArr.push({
                whatToExpect: [""],
                pickUpDrop: "",
                maximumPerson: "",
                startTime: "",
                endTime: "",
                price: "",
                subCategoryId: tourFormik.values.newSubCategory[i].value,
                name: tourFormik.values.newSubCategory[i].label,
                title: "",
                new: true,
            });
        }
        tourFormik.setFieldValue("subCategorySlots", newArr);
    }, [tourFormik.values.newSubCategory]);


    return (
        <FormikProvider value={tourFormik}>
            <div className="row px-3">
                <div className={addstyle["add-property-sc"]}>
                    <div className="col-md-12">
                        <div className={` ${addstyle["form-group"]} ${addstyle["add-image"]} ${addstyle["new-add-gallery"]}`}>
                            <DropImage formik={tourFormik} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className={`${id ? "col-md-4 form-group" : "col-md-6 form-group"}`}>
                                <label>Enter Tour name</label>
                                <Field name="name" placeholder="Enter name" />
                                {tourFormik.errors.name && tourFormik.touched.name ? (
                                    <div className="error">{tourFormik.errors.name}</div>
                                ) : null}
                            </div>
                            <div className={`${id ? "col-md-4 form-group" : "col-md-6 form-group"}`}>
                                <label>Enter City</label>
                                <Field as="select" name="city" onChange={(event) => handleCityChange(event)}>
                                    <option value="">Click to select</option>
                                    {cities.map((city) => (
                                        <option key={city._id} value={city._id}>
                                            {city.name}
                                        </option>
                                    ))}
                                </Field>
                                {tourFormik.errors.city && tourFormik.touched.city ? (
                                    <div className="error">{tourFormik.errors.city}</div>
                                ) : null}
                            </div>
                            {id ? <div className="col-md-4 form-group">
                                <label>Select category</label>
                                <Field
                                    onChange={(e) => {
                                        tourFormik.setFieldValue("categoryId", e.target.value);

                                        subCategoriesList(e.target.value, loader);
                                        tourFormik.setFieldValue("subCategory", []);
                                    }}
                                    as="select"
                                    name="categoryId"
                                >
                                    <option value="">Click to select</option>
                                    {categories.map((category) => (
                                        <option key={category._id} value={category._id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Field>

                                {
                                    tourFormik.errors.categoryId && tourFormik.touched.categoryId
                                        ? <div className="error">
                                            {tourFormik.errors.categoryId}
                                        </div>
                                        :
                                        null
                                }
                            </div> : null}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className={`col-md-12 ${addstyle["form-group"]}`}>
                            <label>Enter Description</label>
                            <MdEditor
                                plugins={EDITOR_PLUGINS}
                                renderHTML={text => mdParser.render(text)}
                                onChange={({ html }) => tourFormik.setFieldValue("description", html)}
                                style={{ height: "50vh" }}
                                view={{ menu: true, md: true, html: false }}
                            />
                            {tourFormik.errors.description && tourFormik.touched.description ? (
                                <div className="error">{tourFormik.errors.description}</div>
                            ) : null}
                        </div>
                    </div>
                    {!id ?
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label>Select category</label>
                                    <Field
                                        onChange={(e) => {
                                            tourFormik.setFieldValue("categoryId", e.target.value);

                                            listSubCategories(e.target.value);
                                            tourFormik.setFieldValue("subCategory", []);
                                        }}
                                        as="select"
                                        name="categoryId"
                                    >
                                        <option value="">click to select</option>
                                        {categories.map((category) => (
                                            <option key={category._id} value={category._id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </Field>
                                    {
                                        tourFormik.errors.categoryId && tourFormik.touched.categoryId
                                            ? <div className="error">
                                                {tourFormik.errors.categoryId}
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <div className="col-md-6 form-group">
                                    <label>Select sub category</label>

                                    <Field
                                        name="subCategory"
                                        id="multiSelectCustom"
                                        placeholder="Select sub-categories"
                                        isMulti={true}
                                        component={MultiSelect}
                                        defaultOption={tourFormik.values.subCategory}
                                        options={subCategories.map((val) => ({ label: val.name, value: val._id }))}
                                    />

                                    {
                                        tourFormik.errors.subCategory && tourFormik.touched.subCategory
                                            ? <div className="error">
                                                {tourFormik.errors.subCategory}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div> : null}

                    {id ? <div className="d-flex justify-content-end">
                        <Button type="button" title={id ? "Edit" : "Add"} handleClick={tourFormik.submitForm} />
                    </div> : null}
                </div>

                <div className={`add-property-sc mt-4 ${tourFormik.values.subCategorySlots.length === 0 ? "d-none" : ""}`}>
                    {tourFormik.values.subCategorySlots.length ?
                        <Fragment>
                            <div className="d-flex justify-content-between">
                                <h4>Sub Categories</h4>
                                {id ? <Field
                                    name="newSubCategory"
                                    id="multiSelectCustom"
                                    placeholder="Select sub-categories"
                                    isMulti={true}
                                    component={MultiSelect}
                                    defaultOption={tourFormik.values.newSubCategory}
                                // options={newSubCategories}
                                /> : null}
                            </div>
                        </Fragment>
                        :
                        null
                    }
                    <div className="col-md-12">
                        <FieldArray
                            name="subCategorySlots"
                            render={() => (
                                tourFormik.values.subCategorySlots.map((item, index) => (
                                    <div key={index} className="mb-3">
                                        <p className={style.subCategoryHeading}>{item.name}</p>
                                        <div className="row mb-3">
                                            <div className={!id || !item.new ? "col-md-6" : "col-md-12"}>
                                                <label>Title</label>
                                                <Field
                                                    name={`subCategorySlots.${index}.title`}
                                                    type="text"
                                                    placeholder="Enter title"
                                                    value={item.title}
                                                />
                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.title`} />
                                            </div>
                                            {!id || item.new ? <div className="col-md-6">
                                                <label>Pick up/drop facility</label>
                                                <Field
                                                    onChange={(e) => tourFormik.setFieldValue(`subCategorySlots.${index}.pickUpDrop`, e.target.value)}
                                                    as="select"
                                                    name={`subCategorySlots.${index}.pickUpDrop`}
                                                >
                                                    <option value="">Click to select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={0}>No</option>
                                                </Field>
                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.pickUpDrop`} />
                                            </div> : null}
                                        </div>
                                        <div className="row mb-3">
                                            {!id || item.new ? <div className="col-md-6">
                                                <label>Price</label>
                                                <Field
                                                    name={`subCategorySlots.${index}.price`}
                                                    type="number"
                                                    validate={(value) => {
                                                        if (!/^\d*$/.test(value)) {
                                                            return "Please enter a whole number for the price.";
                                                        }
                                                    }}
                                                    placeholder="Enter price"
                                                />
                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.price`} />
                                            </div> : null}

                                            {!id || item.new ? <div className="col-md-6">
                                                <label>Maximum Persons</label>
                                                <Field
                                                    min={1}
                                                    name={`subCategorySlots.${index}.maximumPerson`}
                                                    type="number"
                                                    placeholder="Enter total number of person"
                                                    // disabled={!!property}
                                                    pattern="[0-9]*"
                                                    validate={(value) => {
                                                        if (!/^\d*$/.test(value)) {
                                                            return "Please enter a whole number";
                                                        }
                                                    }}
                                                />
                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.maximumPerson`} />
                                            </div> : null}


                                        </div>

                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label>Start Time</label>
                                                <DatePicker
                                                    name={`subCategorySlots.${index}.startTime`}
                                                    onChange={(time) => tourFormik.setFieldValue(`subCategorySlots.${index}.startTime`, time)}
                                                    selected={tourFormik.values.subCategorySlots[index].startTime}
                                                    dateFormat="HH:mm"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                />
                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.startTime`} />
                                            </div>
                                            <div className="col-md-6">
                                                <label>End Time</label>
                                                <DatePicker
                                                    name={`subCategorySlots.${index}.endTime`}
                                                    onChange={(time) => tourFormik.setFieldValue(`subCategorySlots.${index}.endTime`, time)}
                                                    selected={tourFormik.values.subCategorySlots[index].endTime}
                                                    dateFormat="HH:mm"
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                />
                                                <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.endTime`} />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <label>What to expect</label>
                                            {tourFormik.values.subCategorySlots[index].whatToExpect.map((item, innerIndex) => (
                                                <Fragment key={innerIndex}>
                                                    <div className="col-md-10 mb-2">
                                                        <Field
                                                            name={`subCategorySlots.${index}.whatToExpect.${innerIndex}`}
                                                            type="text"
                                                            placeholder="What to Expect"
                                                        />

                                                    </div>
                                                    <div className="col-md-2 d-flex">
                                                        {
                                                            innerIndex === tourFormik.values.subCategorySlots[index].whatToExpect.length - 1 ?
                                                                <i
                                                                    className={`fa fa-plus align-self-center ${style.addSubcatgory}`}
                                                                    onClick={() => addWhatToExpect(index)}
                                                                />
                                                                :
                                                                null
                                                        }
                                                        {
                                                            tourFormik.values.subCategorySlots[index].whatToExpect.length !== 1 ?
                                                                <i
                                                                    className={`fa align-self-center fa-minus ${style.removeSubCategory}`}
                                                                    onClick={() => removeWhatToExpect(index, innerIndex)}
                                                                />
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                </Fragment>
                                            ))}
                                            <ErrorMessage component="div" className="error" name={`subCategorySlots.${index}.whatToExpect`} />
                                        </div>
                                        {id && !item.new ?
                                            <Fragment>

                                                <button type="button" className={style.removeButton} onClick={() => removeSubCategory(item, index)}>Remove</button>
                                                <Button type="button" title="Update" handleClick={() => updateSubCategory(item)} />
                                            </Fragment>
                                            : id ?
                                                <Button type="button" title="Add" handleClick={() => addSubCategory(item, index)} /> : null
                                        }
                                    </div>
                                ))
                            )}
                        />
                        {!id ? <div className="d-flex justify-content-end">
                            <Button type="button" title="Add" handleClick={tourFormik.submitForm} />
                        </div> : null}
                    </div>

                </div>
            </div>
        </FormikProvider>
    );
};

const mapDispatchToProps = (dispatch) => ({
    categoriesList: (query, loader) => dispatch(CommonAction.categoriesList(query, loader)),
    subCategoriesList: (categoryId, loader) => dispatch(CommonAction.subCategoriesList(categoryId, loader)),
    specificTour: (query, loader) => dispatch(VendorTourAction.specific(query, loader)),
    updateTour: (payload, loader, navigate) => dispatch(VendorTourAction.update(payload, loader, navigate)),
    createTour: (payload, loader, navigate) => dispatch(VendorTourAction.create(payload, loader, navigate)),
    updateTourSubCategory: (payload, loader) => dispatch(VendorTourAction.updateTourSubCategory(payload, loader)),
    createTourSubCategory: (payload, loader) => dispatch(VendorTourAction.createTourSubCategory(payload, loader)),
    removeTourSubCategory: (payload, loader) => dispatch(VendorTourAction.removeTourSubCategory(payload, loader)),
    citiesList: (payload, loader, userType) => dispatch(AdminCitiesAction.list(payload, loader, userType)),
});

export default connect(null, mapDispatchToProps)(Addtours);
