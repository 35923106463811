import * as Yup from "yup";

import { MESSAGES } from "../constants";

const editNameSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    unitNumber: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

const editLocationSchema = Yup.object().shape({
    city: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    area: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
    building: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
});

const editPropertyFeatureSchema = Yup.object().shape({
    beds: Yup
        .array()
        .of(
            Yup.object().shape({
                bedRoomName: Yup.string(),
                items: Yup.array().of(
                    Yup.object().shape({
                        bedType: Yup.string().required(MESSAGES.VALIDATION_ERRORS.REQUIRED),
                        totalBeds: Yup.number().required(MESSAGES.VALIDATION_ERRORS.REQUIRED).min(1, MESSAGES.VALIDATION_ERRORS.MINIMUM_BEDROOM),
                    })
                )
            })
        )
        .required(),
    bathrooms: Yup
        .array()
        .of(
            Yup.object().shape({
                private: Yup
                    .mixed()
                    .test("private-bathroom", function (value) {
                        if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
                        return { path: this.path };
                    }),
                ensuite: Yup
                    .mixed()
                    .test("ensuite-bathroom", function (value) {
                        if (this.parent.private === "true") {
                            if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
                        }
                        return { path: this.path };
                    }),
                bedroom: Yup.string().test("attached-bathroom", function (value) {
                    if (this.parent.ensuite === "true") {
                        if (value == 0) {
                            return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
                        }
                    }
                    return { path: this.path };
                }),
                insideBathroom: Yup.mixed().test("inside-bathroom", function (value) {
                    if (this.parent.ensuite === "false") {
                        if (!value) return this.createError({ message: MESSAGES.VALIDATION_ERRORS.REQUIRED, path: this.path });
                    }
                    return { path: this.path };
                }),
            })
        ),
});

const editAmenitiesSchema = Yup.object().shape({
    amenities: Yup
        .array()
        .of(
            Yup.object().shape({
                label: Yup.string(),
                value: Yup.string(),
                checked: Yup.boolean(),
            })
        )
        .test("min-max", function (value) {
            const checked = value.filter(item => item.checked);

            if (checked.length < 1) return this.createError({ message: "At least one amenity is required", path: this.path });
            else if (checked.length > 10) return this.createError({ message: "At most 10 amenities are allowed", path: this.path });

            return { path: this.path };
        })
});


export {
    editNameSchema,
    editLocationSchema,
    editPropertyFeatureSchema,
    editAmenitiesSchema
};