import { Toaster } from "../../../components";
import { MESSAGES, TOAST_TYPE } from "../../../constants";
import { Axios } from "../../../lib";
import COMMON_ACTIONS from "../actions";

const getUserChatRooms = (loader) => async (dispatch) => {
    try {

        dispatch({ type: COMMON_ACTIONS.SET_USER_ROOMS_LOADER, status: true });
        const { data } = await Axios.get("/socket/room", {}, loader);

        dispatch({ type: COMMON_ACTIONS.SET_USER_ROOMS, chatList: data.rooms, count: data.count });
        dispatch({ type: COMMON_ACTIONS.SET_USER_ROOMS_LOADER, status: false });
    }
    catch (err) {
        Toaster(TOAST_TYPE.ERROR, MESSAGES.SOMETHING_WENT_WRONG);
        dispatch({ type: COMMON_ACTIONS.SET_USER_ROOMS_LOADER, status: false });
    }
};

const getSpecificRoom = (roomId, loader) => {
    return Axios.get(`/socket/${roomId}`, {}, loader).then(res => res.data);
};

const getSpecificRoomMessages = (params, loader) => {
    return Axios.get("/socket/messages", params, loader).then(res => res.data);
};

export {
    getUserChatRooms,
    getSpecificRoomMessages,
    getSpecificRoom
};